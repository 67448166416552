import React from 'react';
import { graphql } from 'gatsby';
import {
  ColorDataTableDesign,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Brand"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Brand" />

      <PageNavigation links={['Usage', 'Values']} />

      <Section>
        <Paragraph>
          The brand colors exist to represent Hudl. Generally speaking, these
          colors are intended for marketing and other representations of the
          Hudl brand. Usage within the product itself should be carefully
          considered.
        </Paragraph>
      </Section>

      <Section title="Usage">
        <Paragraph>
          Use of brand colors in the product itself should be minimal. When
          building UI elements, check{' '}
          <Link href="/guidelines/colors/content" isDesignCodeLink>
            content colors
          </Link>
          ,{' '}
          <Link href="/guidelines/colors/utility" isDesignCodeLink>
            utility colors
          </Link>{' '}
          and{' '}
          <Link href="/guidelines/colors/themes" isDesignCodeLink>
            environments
          </Link>{' '}
          instead.
        </Paragraph>
        <DontDo
          dontText="use brand colors for interaction elements."
          doText="use them to communicate on behalf of Hudl."
          imgFilename="colors-brand"
        />
      </Section>

      <Section title="Values">
        <ColorDataTableDesign data={data} />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query brandColorsDesign($type: String = "/Brand/") {
    ...colorDataDesign
  }
`;
